import BaseError from './BaseError';

class ValidationError extends BaseError {
  constructor(response, ...params) {
    super('Validation error occurred', ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }

    this.name = 'ValidationError';
    this.response = response;
    this.validationErrors = response.errors;
  }
}

export default ValidationError;
