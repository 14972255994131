import { get as objGet } from '@/framework/helpers/obj';

// Module routes
import inventoryRoutes from '#/manager/sections/inventory/routes';
import preparationRoutes from '#/manager/sections/preparation/routes';
import suppliersRoutes from '#/manager/sections/suppliers/routes';
import staffRoutes from '#/manager/sections/staff/routes';

const routes = [
  {
    path: 'dashboard',
    name: 'manager/dashboard',
    component: () => import(/* webpackChunkName: "manager-module" */ './views/Dashboard'),
    meta: {
      mainNav: {
        title: 'Dashboard',
      },
    },
  },
  {
    path: 'preparation',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './sections/preparation/PreparationSection'),
    children: preparationRoutes,
    meta: {
      mainNav: {
        title: 'Preparation',
      },
    },
  },
  {
    path: 'inventory',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './sections/inventory/InventorySection'),
    children: inventoryRoutes,
    meta: {
      mainNav: {
        title: 'Inventory',
      },
    },
  },
  {
    path: 'suppliers',
    component: () => import(/* webpackChunkName: "manager-module-suppliers" */ './sections/suppliers/SuppliersSection'),
    children: suppliersRoutes,
    meta: {
      mainNav: {
        title: 'Suppliers',
      },
    },
  },
  {
    path: 'staff',
    component: () => import(/* webpackChunkName: "manager-module-staff" */ './sections/staff/StaffSection'),
    children: staffRoutes,
    meta: {
      mainNav: {
        title: 'Staff',
      },
    },
  },
];

const mainNavItems = () => {
  const navRoutes = [];

  routes.forEach((route) => {
    let thisRoute;

    if (route.children) {
      thisRoute = route.children.find((childRoute) => childRoute.path === '');
      if (!thisRoute) {
        thisRoute = route;
      } else {
        navRoutes.push({ ...thisRoute, meta: route.meta });
        return;
      }
    } else {
      thisRoute = route;
    }

    if (objGet(thisRoute, 'meta.mainNav') === undefined) {
      return;
    }

    navRoutes.push(thisRoute);
  });

  return navRoutes;
};

export default routes;

export {
  routes,
  mainNavItems,
};
