export default {
  namespaced: true,
  state: {
    enabled: false,
  },
  mutations: {
    enable(state) {
      state.enabled = true;
    },
    disable(state) {
      state.enabled = false;
    },
  },
  actions: {
    enable(state) {
      state.commit('enable');
    },
    disable(state) {
      state.commit('disable');
    },
  },
};
