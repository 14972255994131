import BaseError from './BaseError';

class TokenError extends BaseError {
  constructor(...params) {
    super('Token error occurred', ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError);
    }

    this.name = 'TokenError';
  }
}

export default TokenError;
