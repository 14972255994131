import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import WebFont from 'webfontloader';

// Validation
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from '@/framework/lang/en/validation.json';

import App from './App.vue';
import router from './router';
import store from './store';
import {
  ObjDuplicate,
  Viewport,
  Sentry,
  Title,
} from './framework/plugins';
import './registerServiceWorker';

Vue.config.productionTip = false;

// Global plugins
Vue.use(VuePortal);
Vue.use(ObjDuplicate);
Vue.use(Viewport);
Vue.use(Sentry);
Vue.use(Title);

// Add default rules and messages for validation
Object.keys(rules).forEach((rule) => {
  // add the rule
  extend(rule, {
    ...rules[rule],
    message: en.messages[rule],
  });
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    WebFont.load({
      google: {
        families: ['Heebo:700,800', 'Mada:400,600,700'],
      },
    });
  },
}).$mount('#app');
