<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang='scss'>
@import '@/app/assets/sass/base.scss';
</style>
