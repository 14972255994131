export default [
  {
    path: 'suppliers/:date?',
    name: 'preparation/deliveries/suppliers',
    component: () => import(/* webpackChunkName: "preparation-module-components" */ './views/Suppliers'),
    props: true,
    meta: {
      transition: {
        name: 'slide-tray',
        stack: 2,
      },
    },
  },
  {
    path: 'suppliers/:supplierId(\\d+)/:date/delivery',
    name: 'preparation/deliveries/ingredients',
    component: () => import(/* webpackChunkName: "preparation-module-components" */ './views/Ingredients'),
    props: true,
    meta: {
      transition: {
        stack: 3,
      },
    },
  },
];
