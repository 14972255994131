const sizes = {
  desktopLg: {
    min: 1921,
  },
  desktopMd: {
    min: 1601,
    max: 1920,
  },
  desktopSm: {
    min: 1281,
    max: 1600,
  },
  xl: {
    min: 961,
    max: 1280,
  },
  lg: {
    min: 841,
    max: 960,
  },
  md: {
    min: 601,
    max: 840,
  },
  sm: {
    min: 481,
    max: 600,
  },
  xs: {
    max: 480,
  },
};

const getWindowWidth = () => Number(window.innerWidth);

const checkViewportSize = (viewport) => {
  if (sizes[viewport] === undefined) {
    throw new Error('Viewport must be one of the following options: ' + Object.keys(sizes).join(', '));
  }

  return true;
};

export default {
  is(viewport = 'xxl') {
    checkViewportSize(viewport);

    const min = sizes[viewport].min || 0;
    const max = sizes[viewport].max || 999999;
    const currentWidth = getWindowWidth();

    return (currentWidth >= min && currentWidth <= max);
  },
  atLeast(viewport = 'lg') {
    checkViewportSize(viewport);

    const min = sizes[viewport].min || 0;
    const currentWidth = getWindowWidth();

    return (currentWidth >= min);
  },
  atMost(viewport = 'sm') {
    checkViewportSize(viewport);

    const max = sizes[viewport].max || 999999;
    const currentWidth = getWindowWidth();

    return (currentWidth <= max);
  },
  isTouch() {
    return window.ontouchstart !== undefined;
  },
  isMobile() {
    return this.atMost('sm') && this.isTouch();
  },
  isTablet() {
    return (this.atLeast('md') && this.atMost('xl') && this.isTouch());
  },
  isDesktop() {
    return this.atLeast('desktopSm');
  },
};
