import store from '@/store';

const successMessage = (message) => {
  store.dispatch('messages/add', {
    message,
    type: 'success',
  });
};
const warningMessage = (message) => {
  store.dispatch('messages/add', {
    message,
    type: 'warning',
  });
};
const dangerMessage = (message) => {
  store.dispatch('messages/add', {
    message,
    type: 'error',
  });
};

export {
  successMessage as success,
  warningMessage as warning,
  dangerMessage as danger,
};
