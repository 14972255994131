// Module routes
import managerRoutes from '#/manager/routes';
import preparationRoutes from '#/preparation/routes';

export default [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login'),
  },
  {
    path: '/manager',
    component: () => import(/* webpackChunkName: "manager-module" */ '#/manager/ManagerModule'),
    children: managerRoutes,
    meta: {
      auth: {
        requiresSignIn: true,
      },
    },
  },
  {
    path: '/preparation',
    component: () => import(/* webpackChunkName: "preparation-module" */ '#/preparation/PreparationModule'),
    children: preparationRoutes,
    meta: {
      auth: {
        requiresSignIn: true,
      },
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "login" */ './views/Error404'),
  },
];
