export default {
  namespaced: true,
  state: {
    messages: [],
    nextId: 1,
  },
  mutations: {
    add(state, message) {
      state.messages.push(message);
    },
    increment(state) {
      state.nextId += 1;
    },
    remove(state, key) {
      state.messages.splice(key, 1);
    },
    addTimer(state, data) {
      const { index: key, callback, timeout } = data;

      if (state.messages[key].timeoutObj === undefined) {
        state.messages[key].timeoutObj = setTimeout(callback, timeout);
      }
    },
    removeTimer(state, key) {
      if (state.messages[key].timeoutObj !== undefined) {
        clearTimeout(state.messages[key].timeoutObj);
        state.messages[key].timeoutObj = undefined;
      }
    },
  },
  actions: {
    add(state, message) {
      state.commit('increment');
      const newId = state.state.nextId / 1;
      const timeout = message.timeout || 6;

      state.commit('add', {
        ...message,
        id: newId,
        timeoutObj: setTimeout(() => {
          state.dispatch('remove', {
            id: newId,
          });
        }, (timeout * 1000)),
      });
    },
    stopTimer({ commit, state }, data) {
      state.messages.forEach((message, index) => {
        if (message.id === data.id) {
          commit('removeTimer', index);
        }
      });
    },
    startTimer({ commit, dispatch, state }, data) {
      state.messages.forEach((message, index) => {
        if (message.id === data.id) {
          const timeout = message.timeout || 6;
          commit('addTimer', {
            index,
            callback: () => {
              dispatch('remove', {
                id: message.id,
              });
            },
            timeout: (timeout * 1000),
          });
        }
      });
    },
    remove({ commit, state }, data) {
      state.messages.forEach((message, index) => {
        if (message.id === data.id) {
          commit('removeTimer', index);
          commit('remove', index);
        }
      });
    },
  },
};
