import needsPermissions from './needsPermissions';
import requiresSignIn from './requiresSignIn';
import requiresGuest from './requiresGuest';

export default requiresSignIn;
export {
  needsPermissions,
  requiresSignIn,
  requiresGuest,
};
