const stubPlugin = (Vue) => {
  Vue.setModule = () => true;
  Vue.prototype.$sentry = {
    setModule: () => true,
    setUser: () => true,
  };
};

const Sentry = {
  install(Vue) {
    // If Sentry is disabled (or DSN is missing), do not install plugin and stub out all methods
    if (!process.env.VUE_APP_SENTRY_ENABLED || !process.env.VUE_APP_SENTRY_DSN) {
      stubPlugin(Vue);
      return;
    }

    // Enable Sentry
    import(/* webpackChunkName: "sentry" */ '@sentry/browser').then((SentryBrowser) => {
      import(/* webpackChunkName: "sentry" */ '@sentry/integrations').then((Integration) => {
        try {
          SentryBrowser.init({
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.NODE_ENV,
            integrations: [
              new Integration.Vue({
                Vue,
                attachProps: true,
                logErrors: Boolean(process.env.VUE_APP_SENTRY_ENABLE_LOG),
              }),
            ],
          });

          // Define context
          SentryBrowser.configureScope((scope) => {
            scope.setTag('module', 'system');
            scope.setTag('hostname', process.env.VUE_APP_HOSTNAME);
            scope.setTag('apiUrl', process.env.VUE_APP_API_URL);
          });

          // Define global methods
          Vue.setModule = (section) => {
            if (typeof section !== 'string') {
              throw new Error('Section must be specified as a string');
            }
            if (/^[a-zA-Z0-9\-_]+$/.test(section) === false) {
              throw new Error('Section must be a kebab-case or snake_case string');
            }

            SentryBrowser.configureScope((scope) => {
              scope.setTag('module', section.toLowerCase());
            });
          };
        } catch (error) {
          // We couldn't establish a connection with Sentry - stub out methods
          stubPlugin(Vue);
        }
      });
    });

    // Define scoped methods
    Vue.prototype.$sentry = {
      setModule: Vue.setModule,
      setUser: () => true,
    };
  },
};

export default Sentry;
