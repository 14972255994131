export default [
  {
    path: '/preparation/components/overview/:date?',
    name: 'preparation/components/overview',
    component: () => import(/* webpackChunkName: "preparation-module-components" */ './views/Overview'),
    props: true,
    meta: {
      transition: {
        name: 'slide-tray',
        stack: 2,
      },
    },
  },
  {
    path: '/preparation/components/component/:batchId/:id',
    name: 'preparation/components/component',
    component: () => import(/* webpackChunkName: "preparation-module-components" */ './views/Component'),
    props: true,
    meta: {
      transition: {
        stack: 3,
      },
    },
  },
];
