let specifiedTitle = null;
let mainTitle = process.env.VUE_APP_TITLE || 'Your app';
let separator = ' | ';
let includeMain = false;

const set = (title, _includeMain) => {
  includeMain = (_includeMain === undefined)
    ? includeMain
    : _includeMain;

  if (title !== undefined) {
    specifiedTitle = title;
  }
  if (specifiedTitle) {
    document.title = specifiedTitle + ((includeMain) ? separator + mainTitle : '');
  } else {
    document.title = mainTitle;
  }
};

const reset = () => {
  set(null, true);
};

const setMainTitle = (newTitle) => {
  mainTitle = newTitle;
  set(undefined, true);
};

const setSeparator = (newSeparator) => {
  separator = newSeparator;
  set(undefined, true);
};

export {
  set,
  reset,
  setMainTitle,
  setSeparator,
};
