import { get as objGet } from '@/framework/helpers/obj';

const routes = [
  {
    path: '',
    name: 'manager/inventory',
    beforeEnter: (to, from, next) => next({ name: 'manager/products/list' }),
    meta: {
      noPromise: true,
    },
  },
  {
    path: 'products/list',
    name: 'manager/products/list',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ProductList'),
    meta: {
      subNav: {
        section: 'Products',
        title: 'List',
      },
    },
  },
  {
    path: 'products/create',
    name: 'manager/products/create',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ProductForm'),
    meta: {
      subNav: {
        section: 'Products',
        title: 'Create',
      },
    },
  },
  {
    path: 'products/update/:id(\\d+)',
    name: 'manager/products/update',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ProductForm'),
    props: true,
  },
  {
    path: 'products/reporting',
    name: 'manager/products/reporting',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ProductReporting'),
    meta: {
      subNav: {
        section: 'Products',
        title: 'Reporting',
      },
    },
  },
  {
    path: 'products/reporting/:id(\\d+)',
    name: 'manager/products/recall',
    component: () => import(/* webpackChunkName: "manager-module-inventory-reporting" */ './views/ProductRecallMap'),
    props: true,
  },
  {
    path: 'components/list',
    name: 'manager/components/list',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ComponentList'),
    meta: {
      subNav: {
        section: 'Components',
        title: 'List',
      },
    },
  },
  {
    path: 'components/create',
    name: 'manager/components/create',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ComponentForm'),
    meta: {
      subNav: {
        section: 'Components',
        title: 'Create',
      },
    },
  },
  {
    path: 'components/update/:id(\\d+)',
    name: 'manager/components/update',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ComponentForm'),
    props: true,
  },
  {
    path: 'recipeitem/update/:id(\\d+)',
    name: 'manager/recipeitems/update',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/RecipeItemForm'),
    props: true,
  },
  {
    path: 'components/reporting',
    name: 'manager/components/reporting',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/ComponentReporting'),
    meta: {
      subNav: {
        section: 'Components',
        title: 'Reporting',
      },
    },
  },
  {
    path: 'components/reporting/:id(\\d+)',
    name: 'manager/components/recall',
    component: () => import(/* webpackChunkName: "manager-module-inventory-reporting" */ './views/ComponentRecallMap'),
    props: true,
  },
  {
    path: 'ingredients/list',
    name: 'manager/ingredients/list',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/IngredientList'),
    meta: {
      subNav: {
        section: 'Ingredients',
        title: 'List',
      },
    },
  },
  {
    path: 'ingredients/create',
    name: 'manager/ingredients/create',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/IngredientForm'),
    meta: {
      subNav: {
        section: 'Ingredients',
        title: 'Create',
      },
    },
  },
  {
    path: 'ingredients/update/:id(\\d+)',
    name: 'manager/ingredients/update',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/IngredientForm'),
    props: true,
  },
  {
    path: 'ingredients/reporting',
    name: 'manager/ingredients/reporting',
    component: () => import(/* webpackChunkName: "manager-module-inventory" */ './views/IngredientReporting'),
    meta: {
      subNav: {
        section: 'Ingredients',
        title: 'Reporting',
      },
    },
  },
  {
    path: 'ingredients/reporting/:id(\\d+)',
    name: 'manager/ingredients/recall',
    component: () => import(/* webpackChunkName: "manager-module-inventory-reporting" */ './views/IngredientRecallMap'),
    props: true,
  },
];

const sideNavItems = () => routes.filter((route) => objGet(route, 'meta.subNav') !== undefined);

export default routes;

export {
  routes,
  sideNavItems,
};
