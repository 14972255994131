import { get as objGet } from '@/framework/helpers/obj';

const routes = [
  {
    path: '',
    name: 'manager/preparation',
    beforeEnter: (to, from, next) => next({ name: 'manager/preparation/products/list' }),
    meta: {
      noPromise: true,
    },
  },
  {
    path: 'products/list',
    name: 'manager/preparation/products/list',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ProductList'),
    meta: {
      subNav: {
        section: 'Product Batches',
        title: 'List',
      },
    },
  },
  {
    path: 'products/create',
    name: 'manager/preparation/products/create',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ProductForm'),
    meta: {
      subNav: {
        section: 'Product Batches',
        title: 'Create',
      },
    },
  },
  {
    path: 'products/update/:id(\\d+)',
    name: 'manager/preparation/products/update',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ProductForm'),
    props: true,
  },
  {
    path: 'components/list',
    name: 'manager/preparation/components/list',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ComponentList'),
    meta: {
      subNav: {
        section: 'Component Batches',
        title: 'List',
      },
    },
  },
  {
    path: 'components/create',
    name: 'manager/preparation/components/create',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ComponentForm'),
    meta: {
      subNav: {
        section: 'Component Batches',
        title: 'Create',
      },
    },
  },
  {
    path: 'components/update/:id(\\d+)',
    name: 'manager/preparation/components/update',
    component: () => import(/* webpackChunkName: "manager-module-preparation" */ './views/ComponentForm'),
    props: true,
  },
];

const sideNavItems = () => routes.filter((route) => objGet(route, 'meta.subNav') !== undefined);

export default routes;

export {
  routes,
  sideNavItems,
};
