import { set as setTitle } from '@/framework/helpers/title';

const Title = {
  install(Vue) {
    Vue.prototype.$windowTitle = setTitle;

    Vue.mixin({
      created() {
        if (this.$options.windowTitle && typeof this.$options.windowTitle === 'string') {
          setTitle(this.$options.windowTitle);
        }
      },
    });
  },
};

export default Title;
