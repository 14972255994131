const get = (obj, key) => {
  if (typeof obj !== 'object') {
    throw new Error('You can only get the value for an object');
  }
  if (typeof key !== 'string') {
    throw new Error('Key must be a dot-notated string');
  }
  if (key.indexOf('.') !== -1) {
    const levels = key.split('.');
    const prop = levels.shift();

    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      try {
        return get(obj[prop], levels.join('.'));
      } catch {
        return undefined;
      }
    }
  } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }

  return undefined;
};

const set = (obj, key, value) => {
  if (typeof obj !== 'object') {
    throw new Error('You can only set the value for an object');
  }
  if (typeof key !== 'string') {
    throw new Error('Key must be a dot-notated string');
  }
  if (key.indexOf('.') !== -1) {
    const levels = key.split('.');
    const prop = levels.shift();

    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      obj[prop] = {};
    }

    set(obj[prop], levels.join('.'), value);
  } else {
    obj[key] = value;
  }
};

const del = (obj, key) => {
  if (typeof obj !== 'object') {
    throw new Error('You can only set the value for an object');
  }
  if (typeof key !== 'string') {
    throw new Error('Key must be a dot-notated string');
  }

  if (key.indexOf('.') !== -1) {
    const levels = key.split('.');
    const prop = levels.shift();

    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      obj[prop] = {};
    }

    delete obj[prop];
  } else {
    delete obj[key];
  }
};

export {
  get,
  set,
  del,
};
