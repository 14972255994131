import Vue from 'vue';
import Vuex from 'vuex';

import {
  activity,
  messages,
  modals,
  overlay,
  trays,
} from '@/framework/store';
import {
  layout,
  mobileBar,
  session,
} from '@/app/store';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    activity,
    messages,
    modals,
    overlay,
    session,
    trays,
    mobileBar,
    layout,
  },
});
