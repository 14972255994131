import { get as objGet } from '@/framework/helpers/obj';

const routes = [
  {
    path: '',
    name: 'manager/suppliers',
    beforeEnter: (to, from, next) => next({ name: 'manager/suppliers/list' }),
    meta: {
      noPromise: true,
    },
  },
  {
    path: 'list',
    name: 'manager/suppliers/list',
    component: () => import(/* webpackChunkName: "manager-module-suppliers" */ './views/SuppliersList'),
    meta: {
      subNav: {
        section: 'Suppliers',
        title: 'List',
      },
    },
  },
  {
    path: 'create',
    name: 'manager/suppliers/create',
    component: () => import(/* webpackChunkName: "manager-module-suppliers" */ './views/SupplierForm'),
    meta: {
      subNav: {
        section: 'Suppliers',
        title: 'Create',
      },
    },
  },
  {
    path: 'update/:id(\\d+)',
    name: 'manager/suppliers/update',
    component: () => import(/* webpackChunkName: "manager-module-suppliers" */ './views/SupplierForm'),
    props: true,
  },
];

const sideNavItems = () => routes.filter((route) => objGet(route, 'meta.subNav') !== undefined);

export default routes;

export {
  routes,
  sideNavItems,
};
