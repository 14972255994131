export default [
  {
    path: 'overview',
    name: 'preparation/inventory/overview',
    component: () => import(/* webpackChunkName: "preparation-module-inventory" */ './views/Overview'),
    meta: {
      transition: {
        name: 'slide-tray',
        stack: 2,
      },
    },
  },
];
