import { get as objGet } from '@/framework/helpers/obj';

const routes = [
  {
    path: '',
    name: 'staff',
    beforeEnter: (to, from, next) => next({ name: 'manager/staff/list' }),
    meta: {
      noPromise: true,
    },
  },
  {
    path: 'list',
    name: 'manager/staff/list',
    component: () => import(/* webpackChunkName: "manager-module-staff" */ './views/StaffList'),
    meta: {
      subNav: {
        section: 'Staff',
        title: 'List',
      },
    },
  },
  {
    path: 'create',
    name: 'manager/staff/create',
    component: () => import(/* webpackChunkName: "manager-module-staff" */ './views/StaffForm'),
    meta: {
      subNav: {
        section: 'Staff',
        title: 'Create',
      },
    },
  },
  {
    path: 'update/:id(\\d+)',
    name: 'manager/staff/update',
    component: () => import(/* webpackChunkName: "manager-module-staff" */ './views/StaffForm'),
    props: true,
  },
];

const sideNavItems = () => routes.filter((route) => objGet(route, 'meta.subNav') !== undefined);

export default routes;

export {
  routes,
  sideNavItems,
};
