const duplicate = (obj) => JSON.parse(JSON.stringify(obj));

const ObjDuplicate = {
  install(Vue) {
    Vue.duplicate = duplicate;
    Vue.prototype.$duplicate = duplicate;
  },
};

export default ObjDuplicate;
