export default {
  namespaced: true,
  state: {
    registered: [],
    stack: [],
  },
  getters: {
    isOpened: (state) => (id) => (state.stack.slice(-1)[0] === id),
    inStack: (state) => (id) => (state.stack.indexOf(id) !== -1),
    isRegistered: (state) => (id) => (state.registered.indexOf(id) !== -1),
  },
  mutations: {
    registered(state, modalUid) {
      state.registered.push(modalUid);
    },
    opened(state, modalUid) {
      state.stack.push(modalUid);
    },
    closed(state, modalUid) {
      const index = state.stack.indexOf(modalUid);

      if (index !== -1) {
        state.stack.splice(index, 1);
      }
    },
    deregistered(state, modalUid) {
      const index = state.registered.indexOf(modalUid);

      if (index !== -1) {
        state.registered.splice(index, 1);
      }
    },
  },
};
