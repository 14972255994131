export default {
  namespaced: true,
  state: {
    promises: [],
    promiseProgress: 100,
  },
  getters: {
    loading: (state) => state.promises.length > 0,
    progress: (state) => state.promiseProgress,
  },
  mutations: {
    addPromise(state, promise) {
      state.promises.push(promise);
      state.promiseProgress = 100 / (state.promises.length + 1);

      // Wrapp provided promise in another promise we can track
      const wrapper = Promise.resolve(promise);
      wrapper.finally(() => {
        this.commit('activity/removePromise', promise);
      });
    },
    removePromise(state, promise) {
      const index = state.promises.findIndex((item) => item === promise);
      state.promises.splice(index, 1);
      state.promiseProgress = 100 / (state.promises.length + 1);
    },
  },
};
