export default {
  namespaced: true,
  state: {
    title: null,
    backRoute: null,
  },
  mutations: {
    clearTitle(state) {
      state.title = null;
    },
    setTitle(state, data) {
      state.title = data.title;
    },
    clearBackRoute(state) {
      state.backRoute = null;
    },
    setBackRoute(state, data) {
      state.backRoute = data.route;
    },
  },
};
