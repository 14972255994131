class BaseError extends Error {
  constructor(...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BaseError);
    }

    this.name = 'BaseError';
  }

  toString() {
    return this.message;
  }
}

export default BaseError;
