// Module routes
import componentsRoutes from '#/preparation/sections/components/routes';
import productsRoutes from '#/preparation/sections/products/routes';
import deliveriesRoutes from '#/preparation/sections/deliveries/routes';
import inventoryRoutes from '#/preparation/sections/inventory/routes';

const routes = [
  {
    path: '',
    name: 'preparation',
    beforeEnter: (to, from, next) => next({ name: 'preparation/dashboard' }),
    meta: {
      noPromise: true,
    },
  },
  {
    path: 'dashboard',
    name: 'preparation/dashboard',
    component: () => import(/* webpackChunkName: "preparation-module" */ './views/Dashboard'),
    meta: {
      transition: {
        name: 'slide',
        stack: 1,
      },
      nav: {
      },
    },
  },
  {
    path: 'components',
    component: () => import(/* webpackChunkName: "preparation-module-components" */ './sections/components/ComponentsSection'),
    children: componentsRoutes,
  },
  {
    path: 'products',
    component: () => import(/* webpackChunkName: "preparation-module-products" */ './sections/products/ProductsSection'),
    children: productsRoutes,
  },
  {
    path: 'deliveries',
    component: () => import(/* webpackChunkName: "preparation-module-delivery" */ './sections/deliveries/DeliveriesSection'),
    children: deliveriesRoutes,
  },
  {
    path: 'inventory',
    component: () => import(/* webpackChunkName: "preparation-module-delivery" */ './sections/inventory/InventorySection'),
    children: inventoryRoutes,
  },
];

export default routes;
