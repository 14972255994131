import BaseError from './BaseError';

class AuthError extends BaseError {
  constructor(...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.name = 'AuthError';
  }
}

export default AuthError;
