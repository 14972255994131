export default {
  namespaced: true,
  state: {
    registered: [],
    stack: [],
  },
  getters: {
    isOpened: (state) => (id) => (state.stack.slice(-1)[0] === id),
    inStack: (state) => (id) => (state.stack.indexOf(id) !== -1),
    isRegistered: (state) => (id) => (state.registered.indexOf(id) !== -1),
  },
  mutations: {
    registered(state, trayUid) {
      state.registered.push(trayUid);
    },
    opened(state, trayUid) {
      state.stack.push(trayUid);
    },
    closed(state, trayUid) {
      const index = state.stack.indexOf(trayUid);

      if (index !== -1) {
        state.stack.splice(index, 1);
      }
    },
    deregistered(state, trayUid) {
      const index = state.registered.indexOf(trayUid);

      if (index !== -1) {
        state.registered.splice(index, 1);
      }
    },
  },
};
