import Url from 'url-parse';
import store from '@/store';
import fetch from 'cross-fetch';
import ValidationError from '@/framework/errors/ValidationError';

class ApiRequest {
  constructor(url, method, body) {
    if (!url || typeof url !== 'string') {
      throw new Error('You must provide a URL as a string');
    }

    this.url = url;
    this.method = method;
    this.responseCode = 0;

    if (store.state.session.token) {
      this.token = store.state.session.token;
    }

    if (body !== undefined && body !== null) {
      this.body = body;
    }
  }

  /**
   * Set the URL of the API request.
   *
   * @var string value Request URL
   */
  set url(value) {
    const requestUrl = new Url(value, process.env.VUE_APP_API_URL);

    if (requestUrl.href.substr(0, 1) !== '/') {
      requestUrl.set('href', '/' + requestUrl.href);
    }

    this.requestUrl = requestUrl.toString();
  }

  // Alternate URL setter
  setUrl(value) {
    this.url = value;

    return this;
  }

  /**
   * Set the body of the API request.
   *
   * @var Object value Request Body
   */
  set body(value) {
    this.requestBody = value;
  }

  // Alternate body setter
  setBody(value) {
    this.body = value;

    return this;
  }

  /**
   * Set the HTTP method of the API request.
   *
   * @var string value One of the following: GET, HEAD, POST, PUT, DELETE, OPTIONS
   */
  set method(value) {
    const validMethods = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'OPTIONS'];
    this.requestMethod = 'GET';

    if (value && typeof value === 'string' && validMethods.indexOf(value.toUpperCase()) !== -1) {
      this.requestMethod = value.toUpperCase();
    }
  }

  // Alternate method setter
  setMethod(value) {
    this.method = value;

    return this;
  }

  /**
   * Set the API Token to use in this API request.
   *
   * @var string value A valid API Token
   */
  set token(value) {
    this.apiToken = value;
  }

  /**
   * Sets a request header
   *
   * @var string|array header The name of the header to set
   * @var string value The value of the header
   */
  setHeader(header, value) {
    if (this.requestHeaders === undefined) {
      this.requestHeaders = {};
    }

    if (Array.isArray(header)) {
      header.forEach((item, index) => {
        if (typeof item !== 'string' || typeof index !== 'string') {
          return;
        }

        this.requestHeaders[index] = item;
      });
    } else if (typeof header === 'string' && typeof value === 'string') {
      this.requestHeaders[header] = value;
    } else {
      throw new Error('setHeader expects a header name and value, as strings');
    }
  }

  /**
   * Makes the request
   */
  fetch() {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: this.requestMethod,
        headers: this.requestHeaders,
        mode: 'cors',
      };

      let url = this.requestUrl;

      if (this.requestBody !== undefined) {
        if (this.requestMethod === 'GET') {
          const qs = [];

          Object.keys(this.requestBody).forEach((key) => {
            if (this.requestBody[key] !== null && this.requestBody[key] !== undefined) {
              qs.push(key + '=' + encodeURIComponent(this.requestBody[key]));
            }
          });

          if (url.indexOf('?') !== -1) {
            if (url.indexOf('#') !== -1) {
              url = url.replace('#', qs.join('&') + '#');
            } else {
              url += qs.join('&');
            }
          } else if (url.indexOf('#') !== -1) {
            url = url.replace('#', '?' + qs.join('&') + '#');
          } else {
            url += '?' + qs.join('&');
          }
        } else {
          requestOptions.body = JSON.stringify(this.requestBody);
        }
      }

      if (requestOptions.headers === undefined) {
        requestOptions.headers = {};
      }

      if (requestOptions.headers['Content-Type'] === undefined) {
        requestOptions.headers['Content-Type'] = 'application/json';
      }

      if (this.apiToken) {
        requestOptions.headers.Authorization = 'Bearer ' + this.apiToken;
      }

      const requestPromise = fetch(url, JSON.parse(JSON.stringify(requestOptions)));
      store.commit('activity/addPromise', requestPromise);
      requestPromise.then(
        (response) => {
          this.responseCode = response.status;
          const responsePromise = response.json();
          store.commit('activity/addPromise', responsePromise);

          responsePromise.then(
            (jsonData) => {
              if (
                response.ok === true
                && (
                  jsonData.successful === undefined
                  || jsonData.successful === true
                )
              ) {
                resolve(jsonData);
              } else if (this.responseCode === 422) {
                reject(new ValidationError(jsonData));
              } else if (this.responseCode === 401) {
                store.dispatch('session/logout');
                reject();
              } else if (jsonData.message) {
                reject(new Error(jsonData.message));
              } else if (jsonData.messages && Array.isArray(jsonData.messages)) {
                reject(new Error(jsonData.messages[0]));
              } else if (jsonData.messages && jsonData.messages instanceof Object) {
                reject(new Error(Object.values(jsonData.messages)[0]));
              } else if (jsonData.error) {
                reject(new Error(jsonData.error));
              } else {
                reject(new Error('The AJAX request encountered an unknown issue.'));
              }
            },
            () => {
              reject(new Error('Error parsing JSON response'));
            },
          );
        },
        (error) => {
          reject(new Error(error.message));
        },
      );
    });
  }
}

export default ApiRequest;
