export default [
  {
    path: '/preparation/products/overview/:date?',
    name: 'preparation/products/overview',
    component: () => import(/* webpackChunkName: "preparation-module-products" */ './views/Overview'),
    props: true,
    meta: {
      transition: {
        name: 'slide-tray',
        stack: 2,
      },
    },
  },
  {
    path: '/preparation/products/product/:batchId/:id',
    name: 'preparation/products/product',
    component: () => import(/* webpackChunkName: "preparation-module-products" */ './views/Product'),
    props: true,
    meta: {
      transition: {
        stack: 3,
      },
    },
  },
  {
    path: '/preparation/products/group/:batchId/:id',
    name: 'preparation/products/group',
    component: () => import(/* webpackChunkName: "preparation-module-products" */ './views/Product'),
    props: (route) => ({
      batchId: route.params.batchId,
      id: route.params.id,
      group: true,
    }),
    meta: {
      transition: {
        stack: 3,
      },
    },
  },
];
