export default {
  namespaced: true,
  state: {
    transition: null,
  },
  mutations: {
    setTransition(state, data) {
      state.transition = data.transition;
    },
    clearTransition(state) {
      state.transition = null;
    },
  },
};
