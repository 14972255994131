/* eslint-disable no-use-before-define */

import Vue from 'vue';
import Router from 'vue-router';
import emptyPromise from 'empty-promise';
import { danger } from '@/framework/helpers/messages';
import TokenError from '@/framework/errors/TokenError';
import AuthError from '@/framework/errors/AuthError';

// App routes
import routes from '@/app/routes';
import { requiresGuest, requiresSignIn, needsPermissions } from './authRules';
import store from '../store';
import ApiRequest from '../framework/helpers/apiRequest';

// Promise object to track page load
let routePromise = null;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const rules = {
  requiresGuest,
  requiresSignIn,
  needsPermissions,
};

// Add hooks
router.beforeEach((to, _from, next) => {
  if (!to.meta.noPromise) {
    routePromise = emptyPromise();
    store.commit('activity/addPromise', routePromise);
  }

  // Consolidate auth
  const auth = consolidateAuth(to);

  resolveAuth(auth).then(
    () => {
      setTransition(to.meta, _from.meta);
      next();
    },
    (error) => {
      setTransition(to.meta, _from.meta);
      next(error);
    },
  );
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = process.env.VUE_APP_TITLE;
  }

  routePromise.resolve();
});

router.onError((error) => {
  routePromise.resolve();

  if (error.name === 'TokenError') {
    router.push('/');
  } else {
    danger(error);
  }
});

const checkAuth = (auth, session) => new Promise((resolve, reject) => {
  const rulePromises = [];

  Object.keys(auth).forEach((key) => {
    if (!rules[key]) {
      reject(new AuthError('Rule processor "' + key + '" does not exist.'));
    } else {
      rulePromises.push(new Promise((ruleResolve, ruleReject) => {
        rules[key].processor(
          session,
          ruleResolve,
          (message) => {
            ruleReject(new AuthError(message));
          },
          auth[key],
        );
      }));
    }
  });

  if (rulePromises.length > 0) {
    Promise.all(rulePromises).then(
      () => {
        resolve();
      },
      (error) => {
        reject(error);
      },
    );
  } else {
    resolve();
  }
});

const consolidateAuth = (route) => {
  const auth = {
    requiresSignIn: false,
    requiresGuest: false,
    needsPermissions: [],
  };

  route.matched.forEach((pathRoute) => {
    if (pathRoute.meta && pathRoute.meta.auth) {
      if (pathRoute.meta.auth.requiresSignIn === true) {
        auth.requiresSignIn = true;
      }

      if (pathRoute.meta.auth.requiresGuest === true) {
        auth.requiresGuest = true;
      }

      let permissions = [];

      if (typeof pathRoute.meta.auth.needsPermissions === 'string') {
        permissions = [pathRoute.meta.auth.needsPermissions];
      } else if (Array.isArray(pathRoute.meta.auth.needsPermissions)) {
        permissions = pathRoute.meta.auth.needsPermissions;
      }

      permissions.forEach((permission) => {
        if (!Array.includes(auth.needsPermissions)) {
          auth.needsPermissions.push(permission);
        }
      });
    }
  });

  return auth;
};

const resolveAuth = (auth) => new Promise((resolve, reject) => {
  const { session } = store.state;

  if (!session.apiCheck) {
    store.dispatch('session/restoreToken');
    if (!store.state.session.token) {
      reject(new TokenError('You do not have a valid token, or your token has expired.'));
      return;
    }

    const request = new ApiRequest('/auth/current');
    request.fetch().then(
      (result) => {
        store.commit('session/checkedWithApi');
        store.commit('session/setUser', {
          user: {
            id: result.user.id,
            email: result.user.email,
          },
        });

        checkAuth(auth, session).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          },
        );
      },
      (error) => {
        if (request.responseCode === 401) {
          reject(new TokenError('You do not have a valid token, or your token has expired.'));
        } else {
          reject(error);
        }
      },
    );
  } else {
    checkAuth(auth, session).then(
      () => {
        resolve();
      },
      (error) => {
        reject(error);
      },
    );
  }
});

const setTransition = (meta, fromMeta) => {
  let transitionName;

  if (!meta.transition || !fromMeta) {
    store.commit('layout/clearTransition');
  }

  const toTransitionName = (typeof meta.transition === 'object')
    ? meta.transition.name
    : meta.transition;
  const toStack = (typeof meta.transition === 'object')
    ? (meta.transition.stack || 0)
    : 0;
  const fromTransitionName = (typeof fromMeta.transition === 'object')
    ? fromMeta.transition.name
    : fromMeta.transition;
  const fromStack = (typeof fromMeta.transition === 'object')
    ? (fromMeta.transition.stack || 0)
    : 0;

  if (!toTransitionName) {
    store.commit('layout/setTransition', {
      transition: 'none',
    });
    return;
  }

  if (toStack >= fromStack) {
    transitionName = toTransitionName + '-forward';
  } else {
    transitionName = fromTransitionName + '-back';
  }

  store.commit('layout/setTransition', {
    transition: transitionName,
  });
};

export default router;
