export default {
  namespaced: true,
  state: {
    apiCheck: false,
    token: null,
    loggedIn: false,
    user: {},
  },
  mutations: {
    checkedWithApi(state) {
      state.apiCheck = true;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.apiCheck = false;
      state.token = null;
    },
    setUser(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    clearUser(state) {
      state.loggedIn = false;
      state.user = {};
    },
  },
  actions: {
    restoreToken({ commit }) {
      return new Promise((resolve) => {
        commit('checkedWithApi');
        const token = localStorage.getItem('token');
        if (token !== null) {
          commit('setToken', token);
        }
        resolve();
      });
    },
    login({ commit }, response) {
      return new Promise((resolve) => {
        commit('checkedWithApi');
        commit('setToken', response.token);

        // Store token in local storage
        localStorage.setItem('token', response.token);

        commit('setUser', response.user);
        resolve();
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('checkedWithApi');
        commit('clearToken');
        commit('clearUser');

        // Clear token in local storage
        localStorage.removeItem('token');

        resolve();
      });
    },
  },
};
